import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gross',
})
export class PriceGrossPipe implements PipeTransform {
    transform(value: number, vat: number): number {
        return value * (1 + vat / 100);
    }
}
