import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricePipe } from './pipes/price.pipe';
import { PriceGrossPipe } from './pipes/price-gross.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [PricePipe, PriceGrossPipe],
    exports: [PricePipe, PriceGrossPipe],
})
export class PricePipeModule {}
